<template>
  <div>
    <div class="flex bg-white shadow rounded p-4">
      <div class="w-1/4">
        <div class="bg-gray-200 p-4 rounded border mr-4">
          <FormInsights
            v-model="subscribersApi.model"
            @submit="getSubscribers()"
            :isLoading="subscribersApi.isLoading"
          />
        </div>
      </div>
      <div class="w-3/4">
        <UtilLoader :isLoading="subscribersApi.isLoading">
          <div>
            <FormTextInput
              name="search"
              type="search"
              placeholder="Search"
              v-model="search"
              :iconPrefix="faSearch"
              autocomplete="off"
              class=""
              targetClass="w-full"
            />
          </div>

          <table class="w-full">
            <thead>
              <tr>
                <th class="bg-gray-700 text-white font-medium px-3 py-1 text-left">Cohort</th>
                <th class="bg-gray-700 text-white font-medium px-3 py-1 text-left">Subscribers</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(value, key, i) in compCohorts"
                :key="key"
                class="border-white"
                :class="{ 'bg-gray-100': i % 2 == 0, 'bg-gray-200': i % 2 != 0 }"
              >
                <th class="font-normal px-3 py-1 text-left text-gray-600">{{ key | startCase }}</th>
                <td class="font-medium px-3 py-1">{{ value }}</td>
              </tr>
            </tbody>
          </table>
        </UtilLoader>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, startCase } from 'lodash'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import Api from '@/lib/api'
import UtilLoader from '@/components/utils/UtilLoader'
import FormInsights from '@/components/forms/FormInsights'
import FormTextInput from '@/components/forms/elements/FormTextInput'

export default {
  name: 'InsightsSubscribers',
  components: {
    UtilLoader,
    FormInsights,
    FormTextInput
  },
  data() {
    return {
      subscribersApi: new Api(),
      search: '',
      faSearch
    }
  },
  created() {
    this.setSubscribers()
    this.getSubscribers()
  },
  computed: {
    compQueryUrl() {
      let query = cloneDeep(this.subscribersApi.model)
      query.startDate = this.$date(query.startDate).format('DD-MM-YYYY')
      query.endDate = this.$date(query.endDate).format('DD-MM-YYYY')
      return JSON.stringify(query)
    },

    compCohorts() {
      let cohorts = this.subscribersApi.data.cohorts
      let compCohorts = {}

      if (this.search) {
        Object.keys(cohorts).forEach(cohort => {
          if (cohort.replaceAll('-', ' ').includes(this.search.trim().toLowerCase())) {
            compCohorts[cohort] = cohorts[cohort]
          }
        })
      } else {
        compCohorts = cohorts
      }

      return compCohorts
    }
  },
  filters: {
    startCase(value) {
      return startCase(value)
    }
  },
  methods: {
    setSubscribers() {
      this.subscribersApi.model = {
        include: [],
        exclude: [],
        startDate: this.$date(),
        endDate: this.$date(),
        filter: [],
        orderBy: ''
      }
    },

    async getSubscribers() {
      await this.subscribersApi.get('http://localhost:3000/local/analytics/v1/kpis/breakdown/subscribers')

      this.$router.push({ query: { q: this.compQueryUrl } })
    }
  }
}
</script>
